<template>
  <div class="under-maintenance" :class="{'no-products': categories.length === 0}">
    <div class="under-maintenance__container">
      <img
        v-if="categories.length === 0"
        :src="require('@/assets/marketplace/502/maintenance_3.svg')"
        alt="Icon"
      />
      <img
        v-else
        :src="require(`@/assets/marketplace/502/${getIcon()}.svg`)"
        alt="Icon"
      />
      <p class="under-maintenance__title">
        {{ categories.length === 0 ? title_1 : title }}
      </p>
      <p class="under-maintenance__text">{{ text }}</p>
      <button
        type="button"
        class="under-maintenance__button"
        @click="goBack"
      >
        <span>Вернуться назад</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { backNavigateQueue } from "@/helpers/app.js"

export default {
  name: "UnderMaintenancePage",
  data() {
    return {
      title: 'Ведутся технические работы',
      title_1: 'Функционал в разработке',
      text: 'Мы работаем над улучшением ERG Marketplace',
    }
  },
  computed: {
    ...mapState('merch', ['categories']),
  },
  mounted() {
    backNavigateQueue.set(this.goBack);
  },
  methods: {
    getIcon() {
      const icons_list = [
        'maintenance_1',
        'maintenance_2',
        'maintenance_3'
      ]
      return icons_list[~~(icons_list.length * Math.random())]
    },
    goBack() {
      return this.$router.go(-1)
    }
  },
}
</script>

<style scoped lang="scss">
.under-maintenance {
  height: 100%;
  display: grid;
  place-items: center;

  &.no-products {
    height: calc(100vh - 184px);
  }
  &__container {
    width: 60vw;
    text-align: center;

    img {
      margin-bottom: 8px;
    }
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
  }
  &__text {
    color: #8E8E93;
    font-size: 14px;
    line-height: 16px;
  }
  &__button {
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 10px;
    padding: 16px;
    height: 48px;
    width: 100%;
    border-radius: 16px;
    background: #ef7f1a;
    color: #FFF;
  }
}
</style>
