<template>
  <div class="layout__body">
    <div class="dev-methods-list">
      <div
        v-for="(methodName, k) in methodNames"
        :key="k"
        class="dev-methods-list__item"
        @click="() => handleBridgeMethod(methodName)"
      >
        <p>{{ k + '. ' + methodName }}</p>
      </div>
    </div> 
  </div>
</template>

<script>
import bridge from "@/helpers/bridge";
export default {
  name: 'DevPage',
  data(){
    return {
      methodNames: Object.keys(bridge),
    }
  },
  methods: {
    handleBridgeMethod(methodName){
      bridge[methodName]()
        .then((res) => {
          this.$store.dispatch('layout/alert', 'res: '+ res);  
        })
        .catch((err) => {
          this.$store.dispatch('layout/alert', 'err: '+ err);
        })
    }
  }
}
</script>
<style lang="scss">
.dev-methods-list{
  padding: 16px;
  &__item{
    border: 1px solid black;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 4px;
    background: #FFF;
  }
}
</style>